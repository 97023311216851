import "../css/app.css";

import axios from "axios";
import VueAxios from "vue-axios";

window.newsToken = import.meta.env.VITE_AUTH_NEWS;
window.finderToken = import.meta.env.VITE_AUTH_FINDER;

import "../css/app.css";

import { createApp, defineAsyncComponent, onMounted, ref } from "vue";
import VueGoogleMaps from "vue-google-maps-community-fork";
import { ObserveVisibility } from "vue-observe-visibility";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

import CookieConsentVue from './CookieConsentVue.js'

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.NODE_ENV,
});

if (typeof userId !== "undefined" && userId && typeof userName !== "undefined" && userName) {
  Bugsnag.setUser(userId, "", userName);
}
const bugsnagVue = Bugsnag.getPlugin("vue");

// console.log(bugsnagVue);

const DevelopmentMap = defineAsyncComponent(() => import("./components/Maps/DevelopmentMap.vue"));
const DevelopmentKey = defineAsyncComponent(() => import("./components/Maps/DevelopmentKey.vue"));
const DevelopmentHomes = defineAsyncComponent(() => import("./components/Maps/DevelopmentHomes.vue"));

const Overlay = defineAsyncComponent(() => import("./components/Overlay.vue"));

const HomepageCarousel = defineAsyncComponent(() => import("./components/Carousels/HomepageCarousel.vue"));
const HomepageSlide = defineAsyncComponent(() => import("./components/Carousels/HomepageSlide.vue"));
const FeaturedHouseCard = defineAsyncComponent(() => import("./components/Cards/FeaturedHouseCard.vue"));
const HouseCard = defineAsyncComponent(() => import("./components/Cards/HouseCard.vue"));
const ShowHomeCarousel = defineAsyncComponent(() => import("./components/Carousels/ShowHomeCarousel.vue"));
const TestimonialsCarousel = defineAsyncComponent(() => import("./components/Carousels/TestimonialsCarousel.vue"));
const LatestNewsCarousel = defineAsyncComponent(() => import("./components/Carousels/LatestNewsCarousel.vue"));
const HomesCarousel = defineAsyncComponent(() => import("./components/Carousels/HomesCarousel.vue"));
const News = defineAsyncComponent(() => import("./components/News/News.vue"));
const HomeFinder = defineAsyncComponent(() => import("./components/HomeFinder/HomeFinder.vue"));
const PropertyFloorPlanModal = defineAsyncComponent(() => import("./components/Modals/PropertyFloorPlan.vue"));
const PlotModal = defineAsyncComponent(() => import("./components/Modals/Plot.vue"));
const ContactFormDevelopments = defineAsyncComponent(() => import("./components/ContactFormDevelopments.vue"));
const ContactFormStyles = defineAsyncComponent(() => import("./components/ContactFormStyles.vue"));

import ZoomOnHover from "./components/AnimationWrappers/ZoomOnHover.vue";
import FadeInFromBottom from "./components/AnimationWrappers/FadeInFromBottom.vue";
import FlashMessages from "./components/FlashMessages/FlashMessages.vue";
import HamburgerAndClose from "./components/Navigation/HamburgerAndClose.vue";
import Navigation from "./components/Navigation/Navigation.vue";
import SocialMediaIcons from "./components/SocialMediaIcons.vue";

const app = createApp({
  components: {
    DevelopmentMap,
    DevelopmentKey,
    DevelopmentHomes,
    HomepageCarousel,
    HomepageSlide,
    ZoomOnHover,
    FadeInFromBottom,
    FlashMessages,
    HamburgerAndClose,
    Navigation,
    SocialMediaIcons,
    FeaturedHouseCard,
    HouseCard,
    ShowHomeCarousel,
    TestimonialsCarousel,
    LatestNewsCarousel,
    HomesCarousel,
    News,
    HomeFinder,
    PropertyFloorPlanModal,
    PlotModal,
    ContactFormDevelopments,
    ContactFormStyles,
    Overlay,
  },
  setup() {
    const showing = ref(false);
    const plotShowing = ref(null);
    const showFloorplanModal = ref(false);
    const showPlotModal = ref(false);
    const plotImgUrl = ref(null);
    const contactFormSelectedDevelopmentTitle = ref(null);
    const contactFormSelectedDevelopmentId = ref(null);
    const selectedContactFormStyle = ref(null);
    const sending = ref(false);
    const contactActive = ref({
      question: false,
      register: false,
    });
    const contactDevelopmentsSelected = ref([]);
    const contactDevelopmentsError = ref(false);
    const contact_all_or_selected = ref("all");

    function open() {
      showing.value = true;
    }
    function close() {
      showing.value = false;
    }

    function showPlot(id) {
      plotShowing.value = id;
    }

    function openMarker(id) {
      plotShowing.value = id;
    }

    const showFloorPlan = () => {
      showFloorplanModal.value = true;
      if (typeof dataLayer !== "undefined") {
        window.dataLayer.push({ event: "floorplan-view" });
      } else {
        console.log("Would have pushed: floorplan-view");
      }
    };
    const showSitePlan = () => {
      if (typeof dataLayer !== "undefined") {
        window.dataLayer.push({ event: "siteplan-view" });
      } else {
        console.log("Would have pushed: siteplan-view");
      }
      return true;
    };
    const showDataSheet = () => {
      if (typeof dataLayer !== "undefined") {
        window.dataLayer.push({ event: "datasheet-view" });
      } else {
        console.log("Would have pushed: datasheet-view");
      }
      return true;
    };
    const showBrochure = () => {
      if (typeof dataLayer !== "undefined") {
        window.dataLayer.push({ event: "brochure-view" });
      } else {
        console.log("Would have pushed: brochure-view");
      }
      return true;
    };
    const showRegisterForUpdates = () => {
      if (typeof dataLayer !== "undefined") {
        window.dataLayer.push({ event: "register-for-updates-click" });
      } else {
        console.log("Would have pushed: register-for-updates-click");
      }
      return true;
    };
    const showMakeEnquiry = () => {
      if (typeof dataLayer !== "undefined") {
        window.dataLayer.push({ event: "make-enquiry-click" });
      } else {
        console.log("Would have pushed: make-enquiry-click");
      }
      return true;
    };

    const makeContactActive = (content) => {
      contactActive.value = {
        question: false,
        register: false,
      };
      contactActive.value[content] = true;
    };

    const updateContactFormStyle = (style) => {
      selectedContactFormStyle.value = style;
    };

    const updateContactFormDevelopment = (development) => {
      if (development) {
        contactFormSelectedDevelopmentId.value = development.id;
        contactFormSelectedDevelopmentTitle.value = development.title;
      }
    };

    const checkRegisterForm = (e) => {
      if (contactDevelopmentsSelected.value.length) {
        sending.value = true;
        e.target.submit();
        return;
      }
      contactDevelopmentsError.value = true;
      sending.value = false;
    };

    if (window.location.pathname == "/contact-us") {
      // check the query string for a type
      const query = new URLSearchParams(window.location.search);
      const type = query.get("type");
      switch (type) {
        case "question":
          contactFormSelectedDevelopmentId.value = query.get("d");
          contactActive.value.question = true;
          break;
        case "register":
          if (query.get("id")) {
            contactDevelopmentsSelected.value.push(query.get("id"));
          }
          contactActive.value.register = true;
          break;
      }

    }



    return {
      showing,
      showFloorplanModal,
      showPlotModal,
      plotImgUrl,
      contactFormSelectedDevelopmentTitle,
      contactFormSelectedDevelopmentId,
      selectedContactFormStyle,
      sending,
      contactActive,
      contactDevelopmentsSelected,
      contactDevelopmentsError,
      contact_all_or_selected,
      open,
      close,
      showPlot,
      plotShowing,
      openMarker,
      showFloorPlan,
      showSitePlan,
      showDataSheet,
      showBrochure,
      showRegisterForUpdates,
      showMakeEnquiry,
      makeContactActive,
      updateContactFormStyle,
      updateContactFormDevelopment,
      checkRegisterForm,
    };
  },
  watch: {
    showing(newVal) {
      if (newVal) {
        document.body.classList.add('overflow-y-hidden');
      } else {
        document.body.classList.remove('overflow-y-hidden');
      }
    }
  },

  mounted() {

    if (this.$refs.formSuccess && this.$refs.formSuccess.value) {
      switch (this.$refs.formSuccess.value) {
        case "question":
          if (typeof dataLayer !== "undefined") {
            window.dataLayer.push({ event: "enquiry-submitted" });
          } else {
            console.log("Would have pushed: enquiry-submitted");
          }
          break;
        case "updates":
          if (typeof dataLayer !== "undefined") {
            window.dataLayer.push({ event: "register-for-updates-submitted" });
          } else {
            console.log("Would have pushed: register-for-updates-submitted");
          }
          break;
      }
    }




  },

});

app
  .use(bugsnagVue)
  .use(VueAxios, axios)
  .use(CookieConsentVue, window.cookieConsentConfig )
  .provide("axios", app.config.globalProperties.axios)
  .directive("observe-visibility", {
    beforeMount: (el, binding, vnode) => {
      vnode.context = binding.instance;
      ObserveVisibility.bind(el, binding, vnode);
    },
    update: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
  })
  .use(VueGoogleMaps, {
    load: {
      key: import.meta.env.VITE_MAP_KEY,
      v: 3.53,
    },
  })
  .mount("#app");
